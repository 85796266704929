::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: #292929;
}

::-webkit-scrollbar-thumb {
  /* background: #466880; */
  background-color: rgba(207, 216, 220, 0.3);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #000;
}


.row {
    margin-bottom: 0;
}

.row .col {
    margin-bottom: 0;
}


.card {
    transition: opacity 0.1s;
    opacity: 1;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, .1);
    margin: 5rem 0 1rem 0;
}

.card-hidden {
    opacity: 0;
}

.card-error {
    background-color: #424242;
    border-color: rgba(211, 47, 47, .8);
}

.card-success {
    background-color: #424242;
    border-color: rgba(56, 142, 60, .8);
}


.btn {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, .3);
    color: #eeeeee;
    background-color: #424242;
}

.btn:hover {
    color: #424242;
    background-color: #eeeeee;
}

.no-border {
    border: none !important;
    padding: 0;
}

.no-click {
    cursor: auto;
}

.btn:hover {
    box-shadow: none;
}

.btn:disabled {
    background-color: white !important;
}


.btn .preloader-wrapper.small {
    width: 25px;
    height: 25px;
    top: 4px;
    right: 10px;
    margin-left: 19px;
}

.btn .preloader-wrapper .spinner-layer {
}

.btn .preloader-wrapper .circle {
    border-width: 2px;
}


select {
    color: #eeeeee;
    background-color: #222222;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


table {
    display: block;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #404040;
    font-size: 100%;
    line-height: 100%;
    max-height: calc(100vh - 16em);
}

thead {
    display: table;
    background-color: #393939;
    table-layout: fixed;
    width: 100%;
}

tbody {
    display: table;
    table-layout: fixed;
    width: 100%;
}

thead th {
    padding-left: 0.75em;
}

tbody td {
    padding-left: 0.75em;
}

table.highlight>tbody>tr:hover {
    background-color: #515151;
}

table.highlight>tbody>tr.selected {
    background-color: #515151;
}

table>thead>tr>th {
    cursor: pointer;
    position: relative;
}


table>thead>tr>th:after {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 150%;
    white-space: nowrap;
    position: absolute;
    top: 0.75em;
}

table>thead>tr>th.table-sort-up:after {
    content: " arrow_drop_up";
}

table>thead>tr>th.table-sort-down:after {
    content: " arrow_drop_down";
}

.table-thin {
    overflow-y: auto;
}

.table-thin thead {
    width: 100%;
    position: inherit;
}

.table-thin tbody {
    margin-top: 0 !important;
}


nav {
    background-color: #404040;
    box-shadow: none;
}

nav .brand-logo.left {
    left: 1rem;
}

nav .brand-logo span {
    font-size: 50%;
}

nav li b {
    border: 2px solid rgba(207, 216, 220, 0.5);
    padding: 0.75em;
    border-radius: 2px;
}

.invisible-nav {
    background-color: #292929;
}

.invisible-nav button {
    margin-left: 2em;
}

.nav-user {
    margin-right: 1em;
    padding-right: 1em;
    padding-left: 1em;
    margin-top: 0.9em;
    height: 2.75em;
    line-height: 2.75em;
    border-right: 1px solid rgba(207, 216, 220, 0.5);
    border-left: 1px solid rgba(207, 216, 220, 0.5);
    border-radius: 3px;
}

.breadcrumb {
    color: #eeeeee;
}

.breadcrumb .material-icons {
    font-size: 1.25em !important;
    vertical-align: middle;
    margin-top: -4px;
    float: none !important;
}

.breadcrumb span {
    padding-left: 1em;
}


form>.input-field {
    margin: 0;
}

input {
    color: #e0e0e0;
    box-shadow: 0px 1px 0px 0px #424242 !important;
}

input[type=text]:focus {
    border-bottom: 1px solid #9e9e9e !important;
    box-shadow: 0 1px 0 0 #9e9e9e !important;
}
input[type=text]:focus+label {
    color: #9e9e9e !important;
}


.modal-card-blackout {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9999;
    display: none;
}

.modal-card-blackout-open {
    display: block;
}

.modal-card {
    background-color: transparent;
    position: absolute;
    left: calc((100% - 800px) / 2);
    top: 5%;
    width: 800px;
    height: 80%;
    z-index: 99999;
    display: none;
}

.modal-card-thin {
    position: fixed;
    width: 80%;
    left: 10%;
    height: initial;
    background-color: #222222;
}

.modal-card-open {
    display: block;
}


.overview {
    width: 100%;
    height: 700px;
    overflow: hidden;
    margin-bottom: -2em;
}

hr {
    width: 90%;
}

.overview img {
    margin-top: 1em;
    margin-left: 1.5em;
    max-height: 100px;
    margin-bottom: -6em;
}

.overview h5 {
    float: right;
    margin-right: 1em;
    margin-top: 3.8em;
    margin-bottom: -5em;
}


.leaflet-container {
    background-color: transparent !important;
    width: 100%;
    height: 100%;
    transform: scale(1.5);
    z-index: -1;
}

.leaflet-container path {
    stroke-width: 2px;
    fill-opacity: 1;
}

.map-layer-available {
    stroke: #bdbdbd;
    fill: #424242;
}

.map-layer-available:hover {
    stroke: #eeeeee;
}

.map-layer-unavailable {
    stroke: #757575;
    fill: #212121;
    cursor: default !important;
}


.deployments-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.deployment-item {
    width: 25em;
    height: 7.5em;
    background-color: #212121;
    border: 1px solid #eeeeee;
    border-radius: 1px;
    margin: 0.5em;
}

.deployment-item b {
    font-size: 125%;
}
